import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>IRONMAN 70.3 Gulf Coast Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN 70.3 Gulf Coast Race Report" />
        <meta
          property="og:title"
          content="IRONMAN 70.3 Gulf Coast Race Report"
        />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/gulf-coast-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/gulf-coast-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/gulf-coast-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            IRONMAN 70.3 Gulf Coast Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">May 7, 2022</p>
            <p className="lead mb-0">Panama City Beach, FL</p>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            To kick off my third triathlon season, I signed up for a trip to
            Panama City Beach. I have been coming to this beach since I was six
            years old. I was excited to return to my favorite vacation
            destination but this time for an IRONMAN event!
          </p>

          <p>
            This season’s primary goal is to finish an IRONMAN 70.3 in under six
            hours. I’ve raced three of them so far – all in hot weather, and
            each time I flamed out (no pun intended) on the back half of the
            run.
          </p>
          <p>For Gulf Coast 70.3, I had two goals.</p>
          <p>
            The first: leave it all out there and see how I stack up as the
            season begins. With last season’s focus being on a full IRONMAN,
            it’s been 11 months since I raced at this distance. If I crack six
            hours, awesome! If I don’t, that’s okay too! I’ll have a good idea
            of what work is left to get there.
          </p>

          <p>
            The second: I want a complete and successful run. I have a bit of a
            hard time defining what that means. It’s more of a ‘know it when I
            see it’ type of thing. I didn’t have a specific time in mind.
            Instead, it was more about finishing each of the 13 miles strong,
            hopefully in a negative split. No flameouts that lead to a bunch of
            walking this time around!
          </p>

          <p>
            Coming into race week, I also tried to take a step back and
            appreciate where I am in my training.
          </p>

          <p>
            I still consider myself new to this sport, but these are very
            different goals compared to my first 70.3 in Chattanooga in my first
            season. I remember being terrified I wasn’t physically capable of
            finishing. I’ve come a long way in the last two race seasons (and
            that pesky pandemic year). There is plenty of work ahead, but I
            don’t want to lose sight of how things have progressed.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Pre-Race</h1>
          <p>
            Katy and I set off for the beach on Thursday morning. The plan was
            to arrive by 5 p.m. for the athlete briefing. Friday, I’d drop the
            bike off and relax. Saturday, the alarm would be set at 3:30 a.m.
            for an early start at 5:40 a.m. swim into the Gulf of Mexico.
          </p>
          <p className="pb-4">
            We drove in on the bike course. I’m familiar with FL-79, having
            driven it to the beach for literally the last *31* years of my life,
            but I’d never thought about biking on it!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/gulf-coast-race-report/bike-race-sign.jpg"
                alt=""
              />
              <figcaption className="figure-caption">
                Hey! It's more than a just bike race, FDOT sign!
              </figcaption>
            </figure>
          </div>
          <p>
            Friday morning, things took an unexpected turn. I woke up early to
            do my shakeout run. Storms were coming in from our west, and due to
            early morning darkness, the lightning looked extra bright. In
            hindsight, I probably should have done the shakeout on the treadmill
            in the hotel!
          </p>
          <p>
            I took a shower and then prepared everything for race day. I start
            with the obvious things like laying out goggles or shoes. Then get
            into the nitty-gritty like deciding how many gels to carry or which
            team’s visor I’ll wear. Being in Florida, it was an easy decision to
            rep the Noles.
          </p>
          <p>
            With all that done, the only thing left to do was relax! With a
            break in the rain, Katy and I headed to the beach. However, we could
            only enjoy the views for about 45 minutes before more storms
            arrived.
          </p>
          <div className="two-image">
            <img src="/images/gulf-coast-race-report/storms.jpg" alt="" />
            <img src="/images/gulf-coast-race-report/race-prep.jpg" alt="" />
          </div>
          <p>
            Everyone has until 5 p.m. to drop off their bikes in transition. I
            like to drop mine early in the day so there’s one less thing to
            worry about.
          </p>
          <p>
            However, around 10 a.m., IRONMAN sent out a push alert to athletes
            that if you were in the area to find shelter. A few minutes later,
            the National Weather Service placed us under a severe thunderstorm
            warning. About 15 minutes after that, a tornado warning went out for
            half of the bike course, but fortunately, not for where we were.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/radar.jpg" alt="" />
            </figure>
          </div>
          <p>
            Had the race been a day earlier, we all could have been biking
            through a tornado warning. FL-79 has no shelter, and it’s a flat
            road surrounded by pine trees. It’s crazy to think about, and I have
            no idea what might have happened.
          </p>
          <p>
            While the worst of the storm stayed north of Panama City Beach, what
            hit the coast was a devastating scene for athletes who had already
            dropped their bikes off.
          </p>
          <div className="full-image">
            <video
              controls
              poster="/images/gulf-coast-race-report/storm-damage.jpg"
            >
              <source
                src="/images/gulf-coast-race-report/storm-damage.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <p>
            There were dozens and dozens of bikes already checked into
            transition when this storm arrived. The area was a mess –
            porta-potties toppled over, bike racks fell down, and a few athletes
            posted in the Facebook group that the damage was bad enough that
            they couldn’t race. I can’t even imagine how they felt.
          </p>
          <p>
            Some people were pissed, but I don’t hold any ill will towards
            IRONMAN. I don’t know what they could have done. To their credit,
            they moved every single bike under the Edgewater Resort since there
            were more storms to come. As you might have guessed, bike check-in
            was canceled entirely. Now we were bringing everything on race
            morning.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/bikes.jpg" alt="" />
            </figure>
          </div>
          <p>
            By late afternoon, the storms had passed, and Katy and I went back
            out to the beach. The weather was better, but the usually calm Gulf
            of Mexico was roaring - an uncomfortable sight knowing I had to dive
            in soon. I tried to tell myself the surf would calm over the next 12
            hours now that the storms had cleared.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/gulf-coast-race-report/gulf-choppy.jpg"
                alt=""
              />
            </figure>
          </div>
          I ate dinner, set my alarm for 3:30 a.m., and drifted off to sleep.
          I’m always jittery the night before an IRONMAN event, but even amid
          the unexpected nature of today, I surprisingly felt calm!
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            As usual, I woke up before my alarm. I was moving by 3:15 a.m. I had
            a brief moment to chuckle at how ludicrous this hobby is – 3:15 a.m.
            is not “up early.” It is literally the middle of the night.
          </p>
          <p>
            Katy woke up around 4 a.m. to sherpa me a few miles down the street
            to the race site.
          </p>
          <p>
            Without a bike check-in the day before, there was a LONG line to get
            into transition. As I stood there, I noticed the National Weather
            Service had issued a rip current statement. I dismissed it to be
            honest. Having lived in Florida for five years, those are pretty
            common. I still expected to swim.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/line.jpg" alt="" />
            </figure>
          </div>
          <p>
            The line progressed a bit closer to transition and then everyone’s
            phones went off.
          </p>
          <p>The swim was canceled.</p>
          <div className="two-image">
            <img src="/images/gulf-coast-race-report/rip-current.jpg" alt="" />
            <img src="/images/gulf-coast-race-report/no-swim.jpg" alt="" />
          </div>
          <p>
            We’d be starting the race as a time trial with the bike ride. It
            would be two athletes every five seconds, in the order of how we
            checked in at registration. It was no longer a 70.3 triathlon, but
            now a 69.1 duathlon.
          </p>
          <p>This was a huge disappointment for a lot of reasons.</p>
          <p>
            The biggest bummer was I’d lost an opportunity to finish an IRONMAN
            70.3 in under six hours. Without the swim, it was basically a
            guarantee that I’d have a sub-6 time today, but this wasn’t the way
            I wanted to get to that goal! It took a few minutes to shrug that
            disappointment off. I didn’t expect to miss a race goal before the
            day even began.
          </p>
          <p>
            Going by registration time meant we’d have athletes of wildly
            different strengths starting simultaneously, which is not ideal! In
            a typical triathlon, we self-seed by swim time and ability. The swim
            also breaks up the volume and cadence of everyone beginning the bike
            portion.
          </p>
          <p>
            I’d also be starting later in the day. The new 7 a.m. start time
            wasn’t a problem, but I had planned on a 5:40 a.m. start
            nutritionally. Now I’m looking at an extra hour of waiting. The last
            thing I wanted to do was get thirsty or hungry.
          </p>
          <p>
            I drank one of my two bottles I brought for my bike ride while
            waiting around. I tried to tell myself this was now just like a
            typical Saturday with a long ride and a brick run. The race is
            suddenly different, but this should be familiar. Shrug it off,
            pivot, and don’t dwell on what’s gone wrong, Colin!
          </p>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>
          <p>
            <strong>Time:</strong> 0:00
            <br />
            <strong>Distance:</strong> 0 yards
            <br />
            <strong>Pace:</strong> 0:00/100 yd
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Sunny and Humid
            <br />
            <strong>Water Temperature:</strong> 77°F
            <br />
            <strong>Current:</strong> Strong rip currents
          </p>
          <p>Fastest swim of my life!</p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/gulf-coast-race-report/double-red-flags.jpg"
                alt=""
              />
            </figure>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>
          <p>
            <strong>Time:</strong> 0:00
          </p>
          <p>My swim was so fast that there was nothing to transition from!</p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>
          <p>
            <strong>Time:</strong> 2:56:32
            <br />
            <strong>Distance:</strong> 56 miles
            <br />
            <strong>Pace:</strong> 19.1 mph
            <br />
            <strong>AG:</strong> 124/165
            <br />
            <strong>Men:</strong> 870/1143
            <br />
            <strong>Overall:</strong> 1080/1604
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Sunny, 72 degrees, 74% humidity, 20 mph
            winds from the southwest
            <br />
            <strong>Road Conditions:</strong> Paved and in good shape
            <br />
            <strong>Terrain:</strong> Flat AF
          </p>
          <p>
            I’ve never had a swim canceled at a triathlon of any distance. The
            time trial start was new to me, but generally uneventful. I hated
            the idea of two athletes starting every five seconds. I was spooked
            that I wouldn’t be able to get started quickly enough or not be able
            to get my saddles to clip in. But all was well.
          </p>

          <p>
            This course is one of the flattest in the IRONMAN circuit. It’s the
            Florida panhandle, after all. We biked through Panama City Beach and
            then headed north on FL-79. The only hill on the entire ride is
            actually a bridge as we go over the intracoastal waterway. I quickly
            forgot about the hill as I soaked in the fantastic views.
          </p>

          <p>
            The first 30 miles were pretty terrific! I averaged around 21 mph. I
            noticed my power output was a bit higher than I usually like to have
            it. But physically, I felt good and decided to bike by feeling more
            than the power meter. Maybe my FTP had increased during the
            offseason!
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/bike2.jpg" alt="" />
            </figure>
          </div>

          <p>
            As I entered the second hour, I was disappointed I wasn’t going
            faster. It sounds stupid even to type this, but I was sad that I was
            on pace to break my bike PR for this distance by “only” 15 minutes.
          </p>
          <p>
            I have a bad habit of underappreciating my wins and focusing on my
            losses instead. Rather than being ecstatic about how the ride was
            going, I was getting on myself for not making it even better…and
            frankly unrealistic.
          </p>
          <p>
            As I wrestled with that internal mental battle, the course turned
            west. Suddenly I forgot all about my time. I had headwinds to
            battle. The next 10 miles or so were rough. We headed west from
            FL-79 to Powell Lake and the Walton County line. It’s the strongest
            headwind I’ve ever cycled into during a race. It was frustrating to
            look at my power meter, see what I was churning out, and only be
            moving along around 15 mph. On the bright side, I wasn’t hot with
            all that wind blowing into me!
          </p>
          <p>
            I hit the turnaround, cleared the headwind section, and let it rip
            for the last 15 miles back to transition. I was still on track for
            my fastest 70.3 bike time ever. I got there and set a PR! I began to
            dismount and placed my left foot on the ground.
          </p>
          <p>And then, crash.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>
          <p>
            <strong>Time:</strong> 7:09
          </p>
          <p>
            Somebody behind me lost control of their bike at the dismount line.
            He hit me and knocked me over. I was suddenly lying on the asphalt
            on my left side. My bike was on top of me because my right foot was
            still clipped into my pedal.
          </p>
          <p>
            I’m quite fortunate only to have a few minor scratches – same goes
            for the bike. If he hits me at a different angle or if both my feet
            are still clipped in? I could be looking at anything from a busted
            bike to broken bones to a blown ACL.
          </p>
          <p>
            A volunteer came running over and helped me stand up. Somebody else
            brought me one of my bottles and a tube repair kit that flew off my
            bike during the crash. When I saw how far that stuff scattered in
            the parking lot, I was like shit, this was not just a simple fall.
            They asked if I was OK. Honestly, I didn’t quite know! I wasn’t sure
            if I was actually OK or if I was still in that haze before my body
            realized there was an injury.
          </p>
          <p>
            I knew I was good enough to get the bike into transition and away
            from other cyclists coming in. My first priority was just getting
            out of the way, and then I’d assess things.
          </p>
          <p>
            I took a minute to collect myself. It’s jarring to be in any kind of
            bike accident, especially right at the end of a 70.3 course! But I
            seemed to be mostly unscathed and more mentally shaken than anything
            else.
          </p>
          <p>
            I don’t think I’ve ever written more words about a transition in a
            race report, and I’d rather not do it again!
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>
          <p>
            <strong>Time:</strong> 2:26:17
            <br />
            <strong>Distance:</strong> 13.1 miles
            <br />
            <strong>Pace:</strong> 11:11 / mile
            <br />
            <strong>AG:</strong> 122/165
            <br />
            <strong>Men:</strong> 813/1143
            <br />
            <strong>Overall:</strong> 1042/1604
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Sunny, started at 76 degrees, humidity of
            66%, and breezy at times
            <br />
            <strong>Road Conditions:</strong> Almost entirely paved except for a
            few gravel sections
            <br />
            <strong>Terrain:</strong> Also flat AF
          </p>
          <p>
            It’s always hard to transition from cycling to running, even if
            somebody hasn't just crashed into you. It took me about the first
            half mile to mentally let it go and just focus on the task at hand.
          </p>

          <p>
            But I pulled it together! I’m really proud of this. I realized I was
            physically OK to run. I arrived at the first aid station, which came
            early around 0.75 miles in. Things felt a lot better after some
            Gatorade, Coke, and chips!
          </p>

          <p>
            Most of the run course is three loops, each about 4 miles. It’s
            almost all paved roads, entirely flat, and completely exposed to the
            sun. Flat is great, but the sun beat me down last June during
            IRONMAN 70.3 Blue Ridge. I learned my lesson and spent the back half
            of the 2021 season focusing on learning how to run in the heat and
            humidity. I was ready for this!
          </p>

          <p>
            I got towards the end of the first loop feeling great. I saw Katy
            near the pier and yelled out “TWO MORE!” I’d shaken off being
            rattled by the crash. Seeing her gave me a boost too. I realized I
            only had about 9 miles to go and felt great!
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/two-laps.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">"TWO MORE!"</figcaption>
          </div>

          <p>
            The only thing that had me mildly worried is that when I flame out
            on the runs in these IRONMAN events, it has happened quickly. Last
            May in IRONMAN 70.3 Chattanooga, I hit mile 6 feeling awesome, and
            by mile 7, I was down to a run-walk.
          </p>
          <p>
            But I told myself once I got through the second lap, I’d have just
            four miles left, and even if I was tired, I could get through that.
            So, if I got through the second lap, I was basically done. Triathlon
            logic, right?
          </p>
          <p>
            I saw Katy again, screamed out “ONE MORE TIME!” and kept going. As I
            started the third and final loop, I still felt great. It was only by
            about 10 seconds or so, but it was still a negative split compared
            to the first two laps! I tried to thank all the volunteers who I’d
            now seen three times, saw Katy a third time, and headed for the red
            carpet.
          </p>
          <p>
            It wasn’t particularly fast by running standards on its own. I’ve
            run the much hillier ATL Half Marathon almost 25 minutes quicker
            than today’s run time. But given that the run started with a bike
            crash, I’m thrilled with how it went. It felt like a complete run. I
            managed the sun well, my nutrition went smoothly, and my pace
            averaged around 10:30/mile minus walking through the aid stations.
            It’s a far cry from my first 70.3, where I walked the final six
            miles after hitting a wall. It’s not as fast as I want to be, but
            things are trending in the right direction!
          </p>
          <p>This is also a PR for a run on a 70.3 triathlon course!</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Postrace</h1>
          <p>
            <strong>Overall Time:</strong> 5:29:58
            <br />
            <strong>AG:</strong> 122/165
            <br />
            <strong>Men:</strong> 813/1143
            <br />
            <strong>Overall:</strong> 1042/1604
            <br />
          </p>
          <p>
            I had been so focused on forgetting the bike crash and getting
            through the run, I actually forgot what awaited me at the end. It
            feels incredible to reach the finish line of an IRONMAN event.
          </p>

          <p>
            To run down that red carpet, finally stop, and feel your legs wobble
            in gratitude…it’s a huge rush of emotion every single time.
            Finishing these things isn’t easy, and it’s never an accident.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/finish.jpg" alt="" />
            </figure>
          </div>

          <p>
            I can count on one hand the number of times I’ve cried in the last
            few years, and they’ve all been at the finish line of IRONMAN
            events. Once I can finally unfocus from the race, my body’s reaction
            is to release that tension in the form of tears.
          </p>

          <p>
            Somebody had crashed into me as I finished the bike, and I carried
            on to complete my best 70.3 run ever! When I realized that, I
            started balling.
          </p>

          <p>
            Katy found me in the crowds at the finish, and she asked how I felt.
            Talk about a loaded question. In between sobs, I told her I set PRs
            in both disciplines. Plus somebody had crashed into me at the end of
            the bike. I felt amazing, and I felt so physically and emotionally
            tired.
          </p>

          <p>
            I have fallen hard for triathlons for many reasons, but a big one is
            because it’s taught me resilience and adaptability. Whenever I
            finish one of these IRONMAN events, I can’t help but think back to a
            younger me. I used to get flustered easily, to put it mildly. I had
            no ability to compartmentalize one bad thing and stop it from
            poisoning everything else in my day. Or week. Or month!
          </p>

          <p>
            Now? I believe I’ve taken what I’ve learned over my first two
            triathlon seasons and applied it across my life, not just on race
            day. This is the happiest I’ve ever been, both at the end of a 70.3
            and with my life in general.
          </p>

          <p>Little about this race went to plan, but it was amazing.</p>

          <p>
            IRONMAN events usually take place on Sundays, but this one has
            always been on a Saturday. With a rare opportunity for a Chick-fil-A
            visit after the race, I had to take it.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/chickfila.jpg" alt="" />
            </figure>
          </div>

          <p>
            In closing, let me declare how obnoxious it was for the Gulf of
            Mexico to be SO FREAKING CALM on the morning after. It looked like a
            dang lake on Sunday morning.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/gulf-coast-race-report/gulf-calm.jpg" alt="" />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Next Steps</h1>
          <p>
            Not much time to rest! In just two weeks, IRONMAN 70.3 Chattanooga
            awaits! We’ll see how that day goes, but my quest for a sub-6 half
            Ironman will likely have to wait.
          </p>

          <p>
            On the Gulf Coast bike ride, I set new all-time power records for
            5-minute, 10-minute, 20-minute, 60-minute, and 90-minute intervals!
            I’m thrilled to see this kind of progress coming out of the
            offseason.
          </p>

          <p>
            But realistically, I shouldn’t expect to be able to duplicate that
            kind of effort in another 70.3 two weeks later. If I get into the
            back half of the bike ride and sub-6 is looking possible, I
            absolutely plan to go for it, even if it means I flame out on the
            run in pursuit of it. But that can’t be the expectation or goal
            going into Chattanooga.
          </p>

          <p>
            I have a third 70.3 at the end of the season in Augusta, Georgia.
            Assuming sub-6 doesn’t happen in Chattanooga, everything I do in
            terms of training this summer will have that as the single primary
            focus. Hopefully, it’ll be extremely motivating!
          </p>

          <div className="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
